import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Invoice() {
    const userID = ReactSession.get('userId');
    const [orders, setOrders] = useState({});
    const [process, setProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    //console.log(orderId);
    const navigate = useNavigate();

    if(userID <= 0 ){
        navigate('/login');
     }


     const fetchDatas = async () => {
      try {
        const response = await axios.get('https://test.arivomkadai.com/api/offlineorders/');
        setOrders(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };



    useEffect(() => {
      fetchDatas();
      const filteredOrders = orders.length > 0 && orders.filter(order => order.order_id === orderId);
      setProcess(filteredOrders);
    }, [orderId, orders]);

    useEffect(() => {
     
    }, [orders]);



 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};

 const handleDownloadPDF = async () => {
   openModal();
  const input = document.getElementById('pdf-content');
  //input.classList.remove('hide');

  const pdf = new jsPDF({
    orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
    unit: 'pt', // Set unit to millimeters
    format: 'a4', // Set format to A4
  });
  const inputHeight = input.clientHeight;
  const pageHeight = pdf.internal.pageSize.getHeight()+600;
  let currentPosition = 0;
  const scaleFactor = 4; 
  
  // Increase this value for higher resolution and better quality

  while (currentPosition < inputHeight) {
    const canvas = await html2canvas(input, {
      height: Math.min(pageHeight),
      
      y: currentPosition,
      scrollY: -currentPosition,
      scale: scaleFactor
    });

    

    
    const imgData = canvas.toDataURL('image/png', 4);
    pdf.addImage(imgData, 'PNG', 0, 0, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
    //currentPosition +=canvas.height;
    currentPosition += pageHeight;
    if (currentPosition < inputHeight) {
      pdf.addPage();
    }
   
  }
 pdf.save(orderId);
  
  //input.classList.add('hide');
   closeModal();
};




let gst = 0;    
let price = 0;    
let subTotal = 0;    

if (process && process[0]?.orderData) {
    process[0].orderData.forEach(product => {
        const quantity = Number(product.quantity);
        const proprice = Number(product.price);
        const productPrice = proprice * quantity;
        price += productPrice;

        const productGst = (proprice - (proprice / (1 + product.gst / 100))) * quantity;
        gst += productGst;
    });
}

gst = parseFloat(gst).toFixed(2);
subTotal = parseFloat(price - gst).toFixed(2);



  return (
    <>
    
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar" >
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
      
        <div className="content-wrapper">
        <div class="invoice-btn-section clearfix d-print-none">
                        {/* <a href="javascript:window.print()" class="btn btn-lg btn-print">
                            <i class="fa fa-print"></i> Print Invoice
                        </a> */}
                        <button onClick={handleDownloadPDF} className="btn btn-primary me-2 mt-2 ml-5"  style={{float:'right'}}> <i class="fa fa-download"></i> Download Invoice</button>
                                              
                        
                    </div>
    
        <div id="pdf-content" style={{minWidth: '1000px', maxWidth: '1000px', margin:'0px'}}>
            
            

<div class="row invoice-preview">

  <div class="col-xl-12 col-md-8 col-12 mb-md-0 mb-4">
    
  <div style={{ display: 'flex'}}>
        <div  style={{ minWidth: '9%',  minHeight: '100%', background: '#ffc107', }}></div>
        <div style={{minWidth: '87%', maxWidth: '91%', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
        <table style={{borderCollapse: 'collapse', top: '50px', width:'100%'}}>
    
    <tr>
        <td colspan="3" style={{border:'0px',  textAign:'left', fontsize:'12px'}}>
        <img src="logo.png" width="300px" alt="logo"/>
      </td>
        <td width="50%" colspan="4" style={{border:'0px', textAlign:'right', fontSize:'15px', paddingbottom:'20px'}}>
        5D, R.P jaya paradise<br></br>Chitra Nagar, Opposite to Amman kovil Saravanampatti<br></br>Coimbatore – 641 035<br></br>
              Ph : +91 9043842020 <br></br> Email: arivomkadaioffical@gmail.com<br></br>
      </td>
      </tr>
    <tr>
    <td colspan="7"  style={{border: '1px solid #ddd', textAlign: 'left', fontSize: '16px',  background: '#3bb77e', padding: '20px', color: '#fff' }}>
   
    {process && process.length > 0 ? (
      <>
        Invoice: #000{process[0]?.invoiceNumber}<br />
        Order: {process[0]?.order_id}<br />
        Date: {process[0]?.date}
      </>
    ) : (
      <>
        No process found
      </>
    )}


       
    </td>
    </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
    <th colSpan={3} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff' }}>
    Billing Address
  </th>
  <th colSpan={4} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff' }}>
    Shipping Address
  </th>
  
    </tr>	
    
  
        <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
     
    
     {process[0]?.customerData[0].name}<br></br>
     {process[0]?.customerData[0].buiulding} {process[0]?.customerData[0].street}<br></br> {process[0]?.customerData[0].city}, {process[0]?.customerData[0].state}, {process[0]?.customerData[0].pincode}<br></br>
     {process[0]?.customerData[0].email}<br></br>
      Ph :  {process[0]?.customerData[0].number}
  
         
      </td>
        <td colspan="4"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
    
    
     {process[0]?.name}<br></br>
     {process[0]?.buiulding} {process[0]?.street}<br></br> {process[0]?.city}, {process[0]?.state}, {process[0]?.pincode}<br></br>
     Landmark :  {process[0]?.landmark}<br></br>
     {process[0]?.email}<br></br>
    Ph :  {process[0]?.number}
 
 
      </td>
      </tr>
    <tr>
    <th colSpan="3" style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Payment Details</th>
        <th colspan="4"  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Shipping Details</th>
      </tr>
    <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Payment Mode : Online <br></br>
         <small>Ref ID : {process[0]?.payment_id}</small>
        </td>
        <td colspan="4"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Shipping & Handling - Flat Rate <br></br>
      
    (Total Shipping Charges ₹ {process[0]?.shipping}.00)
      
        </td>
      </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
        
        <th  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',background: '#3bb77e',color:'#fff'}}>Products</th>
        <th width='10%'style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',background: '#3bb77e',color:'#fff'}}>SKU</th>
        <th width='10%'style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',background: '#3bb77e',color:'#fff'}}>HSN Code</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Price</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Tax</th>
        <th width='5%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Qty</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Sub Total</th>
    </tr>
    
    {process && process[0]?.orderData.map(product => (
    <tr key={product.id}>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.product}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.code}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.hsn}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price/(1+product.gst/100)).toFixed(2)}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {(product.price-(product.price/(1+product.gst/100))).toFixed(2)} - {product.gst}%</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.quantity}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'right', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price).toFixed(2)}</td>
    </tr>
    ))}
    
    
  
    
    
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Sub Total</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {Number(process[0]?.total).toFixed(2)}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Tax <small>GST</small></td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {Number(gst).toFixed(2)}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Shipping Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {Number(process[0]?.shipping).toFixed(2)}</td>
    </tr>

    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Gift Box Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {Number(process[0]?.gift).toFixed(2)}</td>
    </tr>
    
    <tr>
        <th colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Grand Total</th>
        <th style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹{Number(process[0]?.grand_total).toFixed(2)}</th>
    </tr>
    
    <tr>
        <th colspan="7" tyle={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px', minHeight: '30px'}}>Note</th>
        
    </tr>

  </table>

        </div>
      </div>
  </div>



</div>



          </div>

      


              </div>




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </>
  
  )
}
export default Invoice;
