import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Vendor_detail() {
    const userID = ReactSession.get('userId');
    const [order, setOrder]=useState(null);
    const [orders, setOrders] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const VendorID = queryParameters.get("id");
    const [Items, setItems] = useState("");
    const [vendors, setVendors] = useState("");
    console.log(orders);


    const fetchDatas = async () => {
      try {
        const response = await axios.get('https://test.arivomkadai.com/api/offlineorders/');
        setOrder(response.data);

        
        const allvendors =  response.data.data.filter(item =>item.payment_status ==='paid' && item.status != 'Cancelled');
      
        if (Array.isArray(allvendors)) {
          const filteredvendors = allvendors.filter(order => 
            Array.isArray(order.orderData) && 
            order.orderData.some(item => item.vendor === VendorID)
          );
          console.log("ordersFiltered:", filteredvendors);
          setOrders(filteredvendors);
        }
         
        
      
       
       
         // console.log("orders:", allvendors);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
     
      fetchDatas();
      item();
      
    }, [Items , orders]);

    const item=()=>{
      const lengths= orders && orders.map(products=>(products.orderData) && 
      products.orderData.length);
      console.log(lengths);
    
      
        const sum = lengths.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        console.log(sum); // Outputs the total sum of lengths
      setItems(sum);
    }

    const fetchData = async () => {
   
      // console.log("user:",formData);
     try {
       const response = await axios.get('https://test.arivomkadai.com/api/addvendor/');
       const vendor=response.data.data.filter(vendor=>vendor.id === VendorID);
       setVendors(vendor);
       console.log("vendors:", vendor);
     } catch (error) {
       
       console.error("Error fetching data:", error);
     }
   };
   useEffect(() => {
      fetchData();
    
      }, []);

    



      
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-md-6 col-lg-12 col-xl-12  mb-4">
             
             <div class="card pb-4 ">
             
               <div class="card-header d-flex align-items-center justify-content-between pb-0">
              
                 <div class="card-title mb-0 justify-content-center">
                   <h4 class="m-0 me-2 mb-4 justify-content-center">Vendor  Details</h4>
                   
                 </div>
                 
               </div>
               {vendors && vendors.length > 0 ? (
       
               <div class="card-body">
     <div class="d-flex justify-content-start align-items-center mb-4">
       <div class="avatar me-2">
         <img src={`https://test.arivomkadai.com/api/vendor/${vendors[0].image}`} alt="Avatar" class="rounded-circle"/>
       </div>
       <div class="d-flex flex-column">
         <a href="app-user-view-account.html" class="text-body text-nowrap">
           <h5 class="mb-0">{vendors[0].name}</h5>
           <small class="text-muted">Vendor ID: {vendors[0].id}</small>
         </a>
       </div>
     </div>
     <div class="d-flex justify-content-start align-items-center mb-4">
       <span class="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i class="bx bx-cart-alt bx-sm lh-sm"></i></span>
       <h6 class="text-body text-nowrap mb-0">{Items} Items</h6>
     </div>
     <div class="d-flex justify-content-between">
       <h6>Shipping Details</h6>
        </div>
     <p class=" mb-2"><b>{vendors[0].name}</b></p>
     <p class=" mb-1"> {vendors[0].address}, {vendors[0].state} - {vendors[0].pincode}</p>
    
    
     <p class=" mb-1"><i class="fa fa-envelope"></i> {vendors[0].email}</p>
     <p class=" mb-1"><i class="fa fa-phone"></i> {vendors[0].phonenumber}</p>
      <div>
     
     </div>                     
         

   
   
   </div>
   ) : (
     <>
       No order_ids found
     </>
   )}
     
             </div>
         
           </div>



        <div class="col-lg-12 mb-4 order-0">
                
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title m-0">Cart Details</h5>
        
      </div>
      <div class="card-datatable table-responsive">
        <table class="datatables-order-details table">
          <thead>
            <tr>
            
            
              <th></th>
              <th class="w-50">orderID</th>
              <th class="w-50">products</th>
              <th class="w-25">price</th>
              <th class="w-25">qty</th>
              <th>Amount</th>
              <th>Comission</th>
            </tr>
          </thead>
          <tbody>
          {orders && orders.filter(item =>item.payment_status ==='paid' && item.status != 'Cancelled').map(products=>(products.orderData) && 
              products.orderData.map(product =>(
      <tr className="odd" key={product.id}>
        <td className="control" tabIndex="0" style={{ display: 'none' }}></td>
        <td className="sorting_1">
          <div className="d-flex justify-content-start align-items-center text-nowrap">
            <div className="avatar-wrapper">
              <div className="avatar me-2">
                <img src={`https://test.arivomkadai.com/api/product_data/${product.img}`} alt={product.product} className="rounded-2" />
              </div>
            </div>
          </div>
        </td>
        <td><span>{product.id}</span></td>
        <td>
          <span>{product.product}</span><br />
          <p className="text-muted">{product.code}<br />{product.varient}</p>
        </td>
        <td><span>₹ {Number(product.price).toFixed(2)}</span></td>
        <td><span className="text-body">{product.quantity}</span></td>
        <td><span>₹ {Number(product.subtotal).toFixed(2)}</span></td>
        <td><span>{product.vendorcommission * product.quantity}</span></td>
      </tr>
    )))}
</tbody>

        </table>
        
      </div>
    </div>
   
              </div>
              
              
              </div>


              </div>




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default  Vendor_detail;
