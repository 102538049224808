import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


function Addoflineproduct() {
    const userID = ReactSession.get('userId');
	const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [hsn, setHsn] = useState("");
    const [vendor, setVendor] = useState("");
    const [vendors, setVendors] = useState("");
    const [vendorcomission, setVendorcomission] = useState("");
    const [cat, setCat] = useState("");
	const [keyword, setKeyword] = useState("");
    const [price, setPrice] = useState("");
    const [file, setFile] = useState(null); 
    const [file1, setFile1] = useState(null); 
    const [file2, setFile2] = useState(null); 
    const [file3, setFile3] = useState(null); 
    const [video, setVideo] = useState(null); // Changed empty string to null for file state
    const [des, setDes] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [breadth, setBreadth] = useState("");
    const [value, setValue] = useState("");
    const [weights, setWeights] = useState("");
    const [stock, setStock] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [messure, setMessure] = useState("");
    const [variations, setVariations] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [productCode, setProductCode] = useState(true);
	const [selectedVideo, setselectVideo] = useState('video');
	
    const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};

    if(userID <= 0 ){
        navigate('/login');
     }


     
 const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

  
    
   const handleCode = async (event) => {
    const code = event.target.value;
    setCode(code); 
    
      if(code === '' || code === null){
	  setErrorMessage('Please enter a valid Product code');
	}else{


        const response = await axios.put('https://test.arivomkadai.com/api/addofflineproduct/', { proCode: code });
		const results = response.data;
        const result = response.data.status;
       
		if(result === 'error'){
			const errorMessage = response.data.nextId;
            setErrorMessage(errorMessage);
		}else{
			setErrorMessage('');
		} 
	}
};
    
	
		const getProductID =async()=>{

		const response = await axios.get('https://test.arivomkadai.com/api/addofflineproduct/');
		const results = response.data;
        const result = response.data.nextId;
		setCode('A00'+result);
		//console.log(result);
		};
		
		//console.log(code);
		
		if(productCode === true) {
		  getProductID();
		  setProductCode(false);
	    }
	


	
	
    const handleName = (event) => {
        setName(event.target.value); // Changed setInput to setLink
    };

    const handleHsn = (event) => {
        setHsn(event.target.value); // Changed setInput to setLink
    };


    const handlevendor = (event) => {
        setVendor(event.target.value); // Changed setInput to setLink
    };


    const handlevendorcomission = (event) => {
        setVendorcomission(event.target.value); // Changed setInput to setLink
    };
    
    
	 const handleCat = (event) => {
        setCat(event.target.value); // Changed setInput to setLink
    };
   
   const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };


    const handleStock = (e) => {
        setStock(e.target.value);
    };

   

    const handleMesChange = (event) => {
        setMessure(event.target.value);
    };
  
    const handleVideo = (event) => {
        setVideo(event.target.files[0]); 
    };

    const handleDes = (event, editor) => {
        const data = editor.getData();
        setDes(data);
      };

   


    const handleweight = (event) => {
        setWeight(event.target.value);
    };

    const handleheight = (event) => {
        setHeight(event.target.value);
    };


    const handlebreadth = (event) => {
        setBreadth(event.target.value);
    };

    const handlewidth = (event) => {
        setWidth(event.target.value);
    };
    
    const handleFile = (event) => {
        setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile1 = (event) => {
        setFile1(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile2 = (event) => {
        setFile2(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
    const handleFile3 = (event) => {
        setFile3(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
	
	
	 const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };


    // const variation=[
    //     {}
    // ];


    // const addVariation = () => {
    //     const newVariation = {
    //         value: value,
    //         weight: weights,
    //         price: price, 
    //     };

    //     setVariations([...variations, newVariation]);

    //   console.log(newVariation);
    //     setValue('');
    //     setWeights('');
    //     setPrice('');
    // };
    

    const addVariation = () => {
        if (value && weights && price !== null) {
            // Create a new variation object
            const newVariation = {
                value: value,
                weights: weights,
                price: price
            };
            setVariations([...variations, newVariation]);
            setValue('');
            setWeights('');
            setPrice('');
        } else {
            alert('Please fill in all fields');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('content', des);
        formData.append('image', file);
        formData.append('image1', file1);
        formData.append('image2', file2);
        formData.append('image3', file3);
        formData.append('name', name);
        formData.append('hsn', hsn);
        formData.append('cat', cat);
        formData.append('height', height);
        formData.append('width', width);
        formData.append('breadth', breadth);
        formData.append('weight', weight);
        formData.append('video', video);
        formData.append('stockType', messure);
        formData.append('stock', stock);
		formData.append('code', code);
		formData.append('keyword', keyword);
		formData.append('vendor', vendor);
		formData.append('vendorcommission', vendorcomission);
        
       // console.log('url'+video);
        openModal();
      //  console.log(vendor);
        try {
            const response = await axios.post('https://test.arivomkadai.com/api/addofflineproduct/', formData);
           //console.log(response.data);
            navigate(`/addvariations?id=${response.data.productId}`);
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
        closeModal();
    };


    const fetchData = async () => {
   
        // console.log("user:",formData);
       try {
         const response = await axios.get('https://test.arivomkadai.com/api/addvendor/');
         if(response.data.status === 'success'){
            const vendors = response.data.data.filter(vendor=>vendor.delete === 'no');
            setVendors(vendors);
         }
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };
     useEffect(() => {
        fetchData();
        }, []);

    

    return (


        <>
        <div id="onloadModal" >
        <div class="modal-content"  align='center'>
            <p style={{padding:'20px'}}>Product Loading.</p>
        </div>
         </div>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">


                   




                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Add Product</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Add New Product Details</h5>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
												 <div className="mb-3 col-md-2">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
														 {errorMessage && errorMessage.length > 0 && (
                                              <p className="text-danger">{errorMessage}</p>
                                            )}
                                                    </div>
                                                    <div className="mb-3 col-md-7">
                                                        <label htmlFor="link" className="form-label">Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={name}
                                                            onChange={handleName}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">HSN Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={hsn}
                                                            onChange={handleHsn}
                                                        />
                                                    </div>

													<div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Vendor</label>
                                                       <select
                                                            className="form-control"
                                                            type="text"
                                                            name="vendor"
                                                            id="vendor"
                                                            value={vendor}
                                                            onChange={handlevendor}
                                                        >
                                                      
                                                              {vendors && vendors.map(item => (
                                                                      <option value={item.id}>{item.name}</option>
                                                             ))
                                                            }
														 </select>
                                                    </div>

                                                   

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Vendor Comission %</label>
                                                       <input
                                                            className="form-control"
                                                            type="number"
                                                            name="vendor"
                                                            id="vendorcomission"
                                                            value={vendorcomission}
                                                            onChange={handlevendorcomission}
                                                        />
                                                      
                                                              
                                                    </div>

                                                    <div className="mb-3 col-md-2">
                                                        <label htmlFor="des" className="form-label">Category</label>
                                                       <select
                                                            className="form-control"
                                                            type="text"
                                                            name="cat"
                                                            id="cat"
                                                            value={cat}
                                                            onChange={handleCat}
                                                        >
                                                         <option value="">Select Category</option>
														 <option value="Tech">Tech</option>
														 <option value="Home">Home</option>
														 <option value="Kitchen">Kitchen</option>
														 <option value="Kids">Kids</option>
														 <option value="Gifts">Gifts</option>
														 <option value="Personalcare">Personalcare</option>
														 <option value="Health">Health</option>
														 <option value="Automobile">Automobile</option>
														 <option value="Fashions">Fashions</option>
														<option value="Used">Used</option>
														 </select>
                                                    </div>
                                                  
                                                   
                                                    <div className="mb-3 col-md-2">
                                                        <label htmlFor="des" className="form-label">Stock</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="des"
                                                            id="des"
															rows="3"
                                                            value={stock}
                                                            onChange={handleStock}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-2">
                                                        <label htmlFor="link" className="form-label">Measurement</label>
                                                        <select
                                                              className="form-select"
                                                              name="messure"
                                                              onChange={handleMesChange}
                                                              value={messure}
                                                          >
                                                        {messure === '' ?( <option>Select Type</option>) : (<option selected value={messure} hidden>{messure}</option>)}
														                             <option value="Counts" >Counts</option>
														                             <option value="Kg">Kg</option>
														                             <option Value="L">L</option>
														
                                                         </select>
                                                    </div>
													
													
													<div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>

                                                   {selectedVideo === 'video' ? (
                                                   <div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="video"
                                                            id="video"
                                                            accept="mp4"
                                                            onChange={handleVideo}
                                                        />
                                                    </div>
													):(
													
													<div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                            
                                                        />
                                                    </div>
													)}

                                                   
                                                   

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image 1</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image 2</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image1"
                                                            id="image1"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile1}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image 3</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image2"
                                                            id="image2"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile2}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image 4</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image3"
                                                            id="image3"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile3}
                                                        />
                                                    </div>
                                                   

                                                    <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">DESCRIPTION</label>
                                                        <CKEditor
                                                          editor={ClassicEditor}
                                                          data={des}
                                                          onChange={handleDes}
                                                        />
                                                      
                                                    </div>
													
													
													
													{/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
													
													
                                                </div>
                                                <div className="mt-2">
                                                    <button type="submit" className="btn btn-primary me-2">Next</button>
                                                </div>
                                            </form>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
  )
}
export default  Addoflineproduct;