import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';

 function Testinominal() {
  const userID = ReactSession.get('userId');
  const [first, setFirst] = useState(0); // Index of the first row on the current page
  const [rows, setRows] = useState(15);
  const [testinominal, setTestinominal] = useState({});
  const navigate = useNavigate();
  const [file, setFile] = useState(null); 



  if(userID <= 0 ){
    navigate('/login');
 }
 const handleFile = (event) => {
    setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    const imgElement = document.getElementById('imgSrc');
    const reader = new FileReader();
    reader.onload = function(e) {
      imgElement.src = e.target.result;
      
    };
    reader.readAsDataURL(event.target.files[0]);
};
  

  const handleSubmit = async(event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('image', file);
    try {
        const response = await axios.post('https://test.arivomkadai.com/api/testimonial/', formData);
        
        if(response.data.status === 'success'){
        fetchData();
        const imgElement = document.getElementById('imgSrc');
        const reader = new FileReader();
        reader.onload = function(e) {
          imgElement.src = 'https://test.arivomkadai.com/api/testimonial_data/upload.jpg';
        };
        reader.readAsDataURL(file);

       
      }else{
        alert(response.data.error);
      }
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
    }
  }


 
    const fetchData = async () => {
      try {
        // Make API call to fetch old data
        const response = await axios.get(`https://test.arivomkadai.com/api/testimonial/`);
        setTestinominal(response.data); 
       // console.log(response.data);
      } catch (error) {
        console.error('Error fetching old data:', error);
      }
    };

    useEffect(() => {
    fetchData();
  }, []);


  const removetestinominal = async (productId) => {
    //console.log('Deleting product with ID:', productId); // Log the product ID
    try {
      const response = await axios.put('https://test.arivomkadai.com/api/testimonial/', {id: productId});
      //console.log(response.data);
      fetchData();
     
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  
  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };

  const addFile = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };
  


   //console.log(testinominal.data);

  return (
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="card">
                                           
                                              <div onClick={addFile}>
                                              <img id="imgSrc" src="https://test.arivomkadai.com/api/testimonial_data/upload.jpg" width="100%" />
                                              <input style={{display: 'none'}} id="fileInput"
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                           
                                                  />
                                                 
                                              </div>
                                              <hr></hr>
                                              <div className="card-body">
                                                  <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">Upload</button>
                                              </div>
                                           
                                       
                                    </div>
                                </div>
                            
                                {testinominal && testinominal.data && testinominal.data.slice().reverse().map((item, index) => 
                                  <div className="col-md-2" key={index}>
                                    <div className="card mb-4">
                                      <img src={`https://test.arivomkadai.com/api/testimonial_data/${item.image}`} alt="Avatar"  />
                                      <hr></hr>
                                      <div className="card-body">
                                      <a  onClick={() => removetestinominal(item.id)} class='btn btn-danger w-100'> <i class="bx bx-trash text-white"></i></a>
                                      </div>
                                    </div>
                                  </div>
                                )}


                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
  )
}
export default Testinominal;